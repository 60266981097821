<template>
  <transition name="el-zoom-in-top">
    <div class="batch-operations" v-if="selectedRows.length > 0">
      <span class="hasChosen">{{ $t('userManage.batchModifyRole') }}&nbsp;{{
          selectedRows.length
        }}&nbsp;{{ $t('userManage.items') }}</span>
      <el-button link @click="batchModifyRole"><span class="link-text-style">{{
          $t('userManage.batchModifyRole')
        }}</span></el-button>
      <el-button link @click="batchDeleteAccount"><span class="link-text-delete">{{
          $t('userManage.batchDeleteAccounts')
        }}</span></el-button>
    </div>
  </transition>

  <div class="table-container" v-loading="loading" element-loading-text="Loading...">
    <el-table :header-cell-style="{ background: 'rgb(250, 250, 250)' }" :data="organizationData"
              :row-style="{ height: '65px' }" row-key="id" :border="false" @select="handleSelect"
              @select-all="handleSelectAll" class="table-height"
              :row-class-name="tableRowClassName" table-layout="fixed">
      <el-table-column type="selection" align="center"></el-table-column>
      <el-table-column :label="$t('userManage.name')" prop="name"></el-table-column>
      <el-table-column :label="$t('userManage.account')" prop="account"></el-table-column>
      <el-table-column :label="$t('userManage.belongingOrganization')" prop="parentOrganization"></el-table-column>
      <el-table-column :label="$t('userManage.role')" prop="role">
        <template v-slot="scope">
          <div>
            <el-tag
                v-for="(role, index) in scope.row.role"
                :key="index"
                type="info"
                style="margin-right: 5px;margin-bottom: 5px;"
            >
              {{ role }}
            </el-tag>
          </div>
        </template>
      </el-table-column>


      <el-table-column :label="$t('userManage.creationTime')" prop="createTime"></el-table-column>
      <el-table-column :label="$t('userManage.action')" align="center" fixed="right">
        <template v-slot="scope">
          <el-button link @click="editPassword(scope.row)"><span
              class="link-text-style">{{ $t('userManage.changePassword') }}</span></el-button>
          <el-button link @click="editOrganization(scope.row)"><span class="link-text-style"> {{
              $t('userManage.edit')
            }}</span></el-button>
          <el-button link @click="deleteAccount(scope.row)"><span
              class="link-text-delete">{{ $t('userManage.deleteAccount') }}</span></el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
  <!-- 修改密码弹框   -->
  <el-dialog v-model="updatePasswordDialogVisible" width="20%" style="text-align: left">
    <template #header>
      {{ $t('userManage.changePassword') }}
    </template>

    <hr class="top-separator"/>

    <el-form :model="updatePasswordInfo" :rules="updatePasswordRules" ref="updatePasswordForm" label-width="100px"
             label-position="top">
      <el-form-item :label="$t('userManage.emailAccount')" prop="email">
        <el-input disabled v-model="updatePasswordInfo.email"></el-input>
      </el-form-item>
      <el-form-item :label="$t('userManage.password')" prop="password">
        <el-input type="password" v-model="updatePasswordInfo.password" :show-password="true"
                  :placeholder="$t('userManage.pleaseEnterPassword')"></el-input>
      </el-form-item>

      <hr class="dialog-separator"/>

      <div class="dialog-footer">
        <el-button @click="cancelUpdatePassword" class="custom-reset-button"> {{ $t('userManage.cancel') }}</el-button>
        <el-button class="button-background-color" @click="confirmUpdatePassword" :loading="isButtonLoading">{{
            $t('userManage.confirm')
          }}
        </el-button>
      </div>
    </el-form>
  </el-dialog>

  <!-- 编辑弹框 -->
  <el-dialog v-model="editDialogVisible" width="20%" style="text-align: left;">
    <template #header>
      {{ $t('userManage.editAccount') }}
    </template>

    <hr class="top-separator"/>

    <el-form :rules="editOrganizationRules" :model="editInfo" label-width="100px" label-position="top">
      <el-form-item :label="$t('userManage.name')" prop="name">
        <el-input v-model="editInfo.name" :placeholder="$t('userManage.enterName')"></el-input>
      </el-form-item>
      <el-form-item prop="roleName">
        <label> <span style="color: rgb(245,108,108)">*</span> {{ $t('userManage.role') }}</label>
        <el-select v-model="editInfo.roleName" :placeholder="$t('userManage.pleaseSelectRole')" style="width: 100%;"
                   multiple filterable
                   clearable>
          <el-option v-for="item in roleNameOptions" :key="item.value" :value="item.value"
                     :label="item.label"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('userManage.organization')" prop="organizationName">
        <el-tree-select v-model="editInfo.organizationName" :data="organizationOptions"
                        :props="{ label: 'label', children: 'children', value: 'value' }" check-strictly
                        :placeholder="$t('userManage.pleaseSelectOrganization')"
                        :render-after-expand="false" clearable filterable style="width: 100%;"/>
      </el-form-item>
      <el-form-item prop="country">
        <label> <span style="color: rgb(245,108,108)">*</span> {{ $t('userManage.country') }}</label>
        <el-select v-model="selectedCountries" :placeholder="$t('userManage.selectCountry')"
                   prefix-icon="el-icon-location" clearable
                   style="width: 100%;" label-position="top" multiple filterable @change="handleCountryChange">
          <el-option key="all" :label="$t('userManage.selectAll')" value="all"/>
          <el-option v-for="item in countryOptions" :key="item.value" :label="item.label" :value="item.value"
                     :selected="selectedCountries.includes(item.label)"/>

        </el-select>
      </el-form-item>

      <hr class="dialog-separator"/>

      <div class="dialog-footer">
        <el-button @click="cancelEditOrganizationInfo" class="custom-reset-button"> {{
            $t('userManage.cancel')
          }}
        </el-button>
        <el-button class="button-background-color" @click="confirmEditOrganizationInfo" :loading="isButtonLoading"> {{
            $t('userManage.confirm')
          }}
        </el-button>
      </div>
    </el-form>
  </el-dialog>

  <!-- 刪除账号弹框 -->
  <DeleteDialog
      :visible="deleteAccountDialogVisible"
      :message="$t('userManage.confirmDeleteAccount')"
      @confirm="confirmDeleteAccount"
      @cancel="cancelDeleteAccount"
      @update:visible="deleteAccountDialogVisible = $event"
  ></DeleteDialog>

  <!-- 批量修改角色弹框 -->
  <el-dialog v-model="batchModifyDialogVisible" width="20%" style="text-align: left;">
    <template #header>
      {{ $t('userManage.batchModifyAccountRole') }}
    </template>
    <hr class="top-separator"/>

    <el-form :model="batchModifyInfo" :rules="batchModifyRules" class="centered-form-item">
      <el-form-item prop="roleName">
        <label> <span style="color: rgb(245,108,108)">*</span>{{ $t('userManage.selectRole') }}</label>
        <el-select v-model="batchModifyInfo.roleName" filterable :placeholder="$t('userManage.pleaseSelectRole')"
                   class="wide-select">
          <el-option v-for="item in roleNameOptions" :key="item.value" :value="item.value"
                     :label="item.label"></el-option>
        </el-select>
      </el-form-item>

    </el-form>
    <hr class="dialog-separator"/>
    <div class="dialog-footer">
      <el-button @click="cancelBatchModifyRole" class="custom-reset-button"> {{ $t('userManage.cancel') }}</el-button>
      <el-button class="button-background-color" @click="confirmBatchModifyRole" :loading="isButtonLoading"> {{
          $t('userManage.confirm')
        }}
      </el-button>
    </div>

  </el-dialog>

  <!-- 批量删除账号弹框 -->
  <DeleteDialog
      :visible="batchDeleteDialogVisible"
      :message="$t('userManage.confirmDeleteAccount')"
      @confirm="confirmBatchDeleteAccount"
      @cancel="cancelBatchDeleteAccount"
      @update:visible="batchDeleteDialogVisible = $event"
  ></DeleteDialog>

</template>

<script>
import resizeMixin from '@/mixins/resizeMixin';
import {
  batchDeleteAccounts, batchUpdateAccount,
  deleteAccount,
  getOrganizationsData,
  getRoleData, listToTree, showAccount,
  updateAccount,
  updatePassword
} from "@/api/api";
import {ElMessage} from "element-plus";
import dialogMixin from "@/mixins/dialogMixin";
import countryMixin from "@/mixins/countryMixin";
import DeleteDialog from "@/components/DeleteDialog.vue";

export default {
  components: {DeleteDialog},
  mixins: [resizeMixin, dialogMixin, countryMixin],
  emits: ['data-changed'],
  data() {
    return {
      roleNameOptions: [],

      currentlyDeletingAccountId: null, // 当前正在删除的账号ID
      updatePasswordDialogVisible: false,
      deleteAccountDialogVisible: false,
      editDialogVisible: false,
      updatePasswordInfo: {
        email: '',
        password: '',
      },
      organizationOptions: [],

      updatePasswordRules: {
        email: [
          {
            required: true
          },
        ],
        password: [
          {
            required: true,
            message: this.$t('userManage.password_required'),
            trigger: 'blur'
          },
          {
            pattern: /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])[A-Za-z\d\W_]{8,20}$/,
            message: this.$t('userManage.password_required'),
            trigger: 'blur',
          }
        ],
      },
      editInfo: {
        id: null, // 添加id字段
        name: '',
        roleName: [],
      },
      editOrganizationRules: {
        name: [
          {
            required: true, message: this.$t('userManage.enterName'), trigger: 'blur'
          }
        ],
        roleName: [
          {
            required: true, message: this.$t('userManage.pleaseSelectRole'), trigger: 'blur'
          }
        ],
        organizationName2: [
          {
            required: true, message: this.$t('userManage.pleaseSelectOrganization'), trigger: 'blur'
          }
        ],
        country: [
          {
            required: true, message: this.$t('userManage.selectCountry'), trigger: 'blur'
          }
        ],
      },
      selectedRows: [],//表示表格多选框选中的行
      batchModifyDialogVisible: false,//批量修改角色的弹框可见性属性
      batchDeleteDialogVisible: false,//批量删除账号的弹框可见性属性
      batchModifyInfo: {//批量修改角色的弹框的下拉框属性
        roleName: '',
      },
      batchModifyRules: {//批量修改角色弹框的表单校验规则
        roleName: [
          {
            required: true
          },
        ]
      },
      countryOptions: [],
      selectedCountries: [],
      dataLoaded: false // 标志位，用于控制接口调用
    }
  },
  props: {
    organizationData: Array,
    loadData: {
      type: Function,
      required: true,
    },
    loading: Boolean  // 接收 loading 状态
  },
  methods: {
    // 当用户点击编辑按钮时调用
    async editOrganization(row) {
      try {
        if (!this.dataLoaded) {
          await this.fetchCountryOptions();
          await this.fetchRoleNameOptions();
          await this.fetchOrganizationOptions();
          this.dataLoaded = true;
        }
        const accountDetails = await showAccount(row.id);
        this.editInfo.id = accountDetails.data.id; // 设置id
        // 根据获取的数据更新编辑表单的数据
        this.editInfo.name = accountDetails.data.name;
        this.editInfo.roleName = accountDetails.data.role_ids; // 假设角色信息在 'role' 字段中
        this.editInfo.organizationName = accountDetails.data.organization_id; // 假设组织ID在 'organization_id' 字段中
        this.selectedCountries = accountDetails.data.country_ids; // 重置国家选择，需要根据实际情况调整

        this.editDialogVisible = true;
      } catch (error) {
        console.error('Error fetching account details:', error);
        // 处理错误情况
      }
    },

    //点击删除账号功能的方法
    deleteAccount(organization) {
      this.currentlyDeletingAccountId = organization.id;
      this.deleteAccountDialogVisible = true;
    },
    editPassword(organization) {
      this.updatePasswordDialogVisible = true;
      this.updatePasswordInfo.email = organization.account;
      this.updatePasswordInfo.id = organization.id; // 用户 ID
    },
    async confirmUpdatePassword() {
      await this.handleButtonAction(async () => {
        // 首先验证表单数据是否有效
        const isValid = await this.$refs.updatePasswordForm.validate();
        if (isValid) {
          // 表单验证通过，获取更新密码所需的数据
          const requestData = {
            id: this.updatePasswordInfo.id, // 从表单数据中获取用户 ID
            email: this.updatePasswordInfo.email,
            password: this.updatePasswordInfo.password
          };

          // 调用后端接口，传递所需的参数
          const response = await updatePassword(requestData);

          // 处理后端接口的响应结果
          if (response.code === 0) {
            // 密码更新成功
            ElMessage.success(this.$t('userManage.passwordChangeSuccess'));
            this.updatePasswordDialogVisible = false; // 关闭弹框
          } else {
            ElMessage.error(this.$t('userManage.password_change_failed'));
            // 密码更新失败
            console.error('Failed to update password:', response.data.error);
          }
        } else {
          // 表单验证失败
          console.error('Form validation failed');
        }
      });
    },

    cancelUpdatePassword() {
      this.updatePasswordDialogVisible = false;
    },

    async confirmDeleteAccount() {
      if (this.currentlyDeletingAccountId) {
        try {
          await deleteAccount(this.currentlyDeletingAccountId);
          this.$emit('data-changed');
        } catch (error) {
          console.error('Failed to delete account:', error);
        }
      }
      this.deleteAccountDialogVisible = false;
      this.currentlyDeletingAccountId = null; // 清空当前正在删除的账号ID
    },

    //点击删除功能的取消按钮
    cancelDeleteAccount() {
      this.deleteAccountDialogVisible = false;
    },
    //点击编辑弹框的取消按钮
    cancelEditOrganizationInfo() {
      this.editDialogVisible = false;
    },
    // 当用户点击确认按钮以保存编辑后的账号信息时调用
    async confirmEditOrganizationInfo() {
      await this.handleButtonAction(async () => {
        const updateData = {
          name: this.editInfo.name,
          role_ids: this.editInfo.roleName,
          organization_id: this.editInfo.organizationName,
          country_ids: this.selectedCountries,
        };
        await updateAccount(this.editInfo.id, updateData);
        this.editDialogVisible = false;
        await this.loadData();
      });
    },
    handleSelect(selection) {
      this.selectedRows = selection;
    },
    handleSelectAll(selection) {
      this.selectedRows = selection;
    },
    //点击批量修改角色的方法
    async batchModifyRole() {
      this.batchModifyDialogVisible = true;
      await this.fetchRoleNameOptions();
    },
    //点击批量删除账号的方法
    batchDeleteAccount() {
      this.batchDeleteDialogVisible = true;
    },
    cancelBatchDeleteAccount() {
      this.batchDeleteDialogVisible = false;
    },
    async confirmBatchDeleteAccount() {
      try {
        // 获取选中行的ID列表
        const selectedIds = this.selectedRows.map(row => row.id);

        const requestData = {
          ids: selectedIds,
        };
        // 向后端发送批量删除账号的请求
        await batchDeleteAccounts(requestData);

        // 执行成功后的操作，例如提示用户操作成功
        this.$message.success(this.$t('userManage.batch_delete_account_success'));
        // 关闭批量删除对话框
        this.batchDeleteDialogVisible = false;

        // 重新加载数据，以更新表格显示
        await this.loadData();

        // 清空选中行列表
        this.selectedRows = [];
      } catch (error) {
        // 处理错误情况，例如提示用户操作失败
        this.$message.error(this.$t('userManage.batch_delete_account_failed'));
        console.error('Error in confirmBatchDeleteAccount:', error);
      }
    },
    cancelBatchModifyRole() {
      this.batchModifyDialogVisible = false;
    },
    async confirmBatchModifyRole() {
      await this.handleButtonAction(async () => {
        // 获取选中行的ID列表
        const selectedIds = this.selectedRows.map(row => row.id);

        // 构造请求的数据对象
        const requestData = {
          ids: selectedIds, // 选中行的ID列表
          role_id: this.batchModifyInfo.roleName // 新的角色信息
        };

        await batchUpdateAccount(requestData);
        // 执行成功后的操作，例如提示用户操作成功
        this.$message.success(this.$t('userManage.batch_modify_role_success'));
        // 数据保存成功后，关闭编辑弹框
        this.batchModifyDialogVisible = false;

        await this.loadData();
        // 清空选中行列表，如果不需要清空，可以省略这一步
        this.selectedRows = [];
      });
    },
    handleCountryChange(value) {
      if (value.includes('all')) {
        // 检查是否选择了“全选”
        if (this.selectedCountries.length === this.countryOptions.length + 1) {
          // 如果已全选，点击全选将取消选择所有项
          this.selectedCountries = [];
        } else {
          // 否则，选择所有国家
          this.selectedCountries = this.countryOptions.map(item => item.value);
          this.selectedCountries.push('all'); // 确保全选也被选中
        }
      } else if (this.selectedCountries.length === this.countryOptions.length) {
        // 如果所有国家都被选中，确保全选也被选中
        this.selectedCountries.push('all');
      } else {
        // 如果不是所有国家被选中，确保全选不被选中
        this.selectedCountries = this.selectedCountries.filter(item => item !== 'all');
      }
    },
    tableRowClassName({row}) {
      if (this.selectedRows.includes(row)) {
        return 'row-selected';
      }
      return '';
    },
    async fetchRoleNameOptions() {
      try {
        // 调用后端接口获取国家列表数据
        const response = await getRoleData();
        // 将后端返回的国家列表数据转换为前端需要的格式
        this.roleNameOptions = response.data.map(role => ({
          value: role.id,
          label: role.name
        }));
      } catch (error) {
        console.error('Error fetching role options:', error);
        // 处理错误
      }
    },

    async fetchOrganizationOptions() {
      try {
        const response = await getOrganizationsData();
        const treeData = listToTree(response.data);
        this.organizationOptions = this.transformOrganizationsData(treeData);
      } catch (error) {
        console.error('Error fetching organization options:', error);
      }
    },
    transformOrganizationsData(organizations) {
      return (organizations || []).map(org => ({
        value: org.id,
        label: org.name,
        children: this.transformOrganizationsData(org.children),
      }));
    },
  },
};
</script>
<style scoped>
.table-container {
  overflow: auto hidden;
}

/*:deep( .el-table .el-table__body .el-table__row.row-selected),
:deep(.el-table .el-table__body .el-table__row.row-selected:hover) {
  background-color: #409EFF;
  !* 蓝色背景，保持选中状态 *!
}*/

.centered-form-item .el-form-item {
  width: 85%;
}

.wide-select {
  width: 100%;
  /* 调整为所需的宽度 */
}

/*.row-selected {
  background-color: #409EFF;
  !* 蓝色背景 *!
}*/

:deep( .el-form-item__label ) {
  margin-bottom: 0;
  /* 减少label下方的外边距 */
  padding-bottom: 0;
  /* 减少label的内边距 */
}

.dialog-footer {
  display: flex;
  justify-content: flex-end;
}

/*表格上方的div方框样式*/
.batch-operations {
  border: 1px solid rgb(0, 154, 68);
  background-color: rgb(238, 250, 235);
  text-align: left;
  height: 50px;
  /* 设置合适的高度值 */

  display: flex;
  gap: 20px;
  align-items: center;
  margin-bottom: 15px;
}

.hasChosen {
  margin-left: 10px;
  font-size: 14px;
  margin-right: 10px;
}

/*批量修改角色弹框的下拉框居中展示样式*/
.centered-form-item {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
</style>