<template>
  <div class="search-bar">
    <el-select v-model="searchType" placeholder="" class="search-type" filterable>
      <el-option :label="$t('userManage.account')" value="account"></el-option>
      <el-option :label="$t('userManage.name')" value="name"></el-option>
      <el-option :label="$t('userManage.role')" value="role"></el-option>
    </el-select>

    <!-- 根据搜索类型决定显示文本框还是下拉框 -->
    <template v-if="searchType !== 'role'">
      <el-input v-model="searchKeyword" placeholder="" class="search-keyword" clearable></el-input>
    </template>
    <template v-else>
      <el-select filterable v-model="searchRole" placeholder="" class="search-role" style="width: 200px;" append-to-body
                 clearable :allow-create="true">
        <el-option v-for="item in roleNameOptions" :key="item.value" :value="item.label"
                   :label="item.label"></el-option>
      </el-select>&nbsp;&nbsp;&nbsp;&nbsp;
    </template>

    <el-button class="button-background-color" @click="search">{{ $t('userManage.search') }}</el-button>

  </div>
</template>

<script>
import {getRoleData} from "@/api/api";

export default {
  data() {
    return {
      searchType: "account",
      searchKeyword: "",
      searchRole: null,// 用于存储搜索的角色
      roleNameOptions: [],

    };
  },
  mounted() {
    this.fetchRoleNameOptions();
  },
  methods: {
    search() {
      let searchCondition = {};

      // 根据选定的搜索类型指定搜索字段和模糊搜索条件
      if (this.searchType === "account") {
        searchCondition["email-like"] = this.searchKeyword;
      } else if (this.searchType === "name") {
        searchCondition["name-like"] = this.searchKeyword;
      } else if (this.searchType === "role") {
        searchCondition["role"] = this.searchRole;
      }
      // 传递搜索条件给父组件
      this.$emit("search", searchCondition);
    },
    reset() {
      this.$emit("reset");
    },
    async fetchRoleNameOptions() {
      try {
        // 调用后端接口获取国家列表数据
        const response = await getRoleData();
        // 将后端返回的国家列表数据转换为前端需要的格式
        this.roleNameOptions = response.data.map(role => ({
          value: role.id,
          label: role.name
        }));
      } catch (error) {
        console.error('Error fetching role options:', error);
        // 处理错误
      }
    },
  },
};
</script>

<style scoped>
.search-keyword {
  width: 200px;
  margin-right: 20px;
}

.search-type {
  width: 100px;
}

.search-bar {
  text-align: left;
  margin-bottom: 20px;
  margin-left: 0;
}
</style>
  