import { request } from '@/utils/http'

export default {
  menuList: () => request.get('/menu/list'),
  role: (id) => request.get(`/roles/${id}`),
  roles: () => request.get('/roles'),
  roleSimple: () => request.get('/role-simple'),
  updateRole: (data) => request.put(`/roles/${data.id}`,data),
  roleDelete: (id) => request.delete(`/roles/${id}`),
  addRole: (data) => request.post('/roles',data),
  roleMenuPermissions: (data) => request.post('/role/menu-permission',data)
}