<template>
  <div>
    <hr class="separator " style="margin-top: 1px"/>
    <div class="custom-text">
      <span class="user-manage"> {{ $t('userManage.userManagement') }}</span>
      &nbsp;/&nbsp; {{ $t('userManage.organizationManagement') }}
    </div>
    <hr class="separator"/>
  </div>

  <div class="organization-management">
    <!-- 左侧新增组织组件 -->
    <div class="directory">
      <AddOrganization @organizationSelected="handleOrganizationSelected"/>
    </div>
    <!-- 右侧组织列表和搜索组件 -->
    <div class="content">
      <OrganizationList :selectedOrganization="selectedOrganization"/>
    </div>
  </div>
</template>

<script>
import AddOrganization from "./components/AddOrganization"; // 路径根据实际情况调整
import OrganizationList from "./components/OrganizationList"; // 路径根据实际情况调整

export default {
  components: {
    AddOrganization,
    OrganizationList,
  },
  data() {
    return {
      selectedOrganization: null,
    };
  },
  methods: {
    handleOrganizationSelected(organization) {
      this.selectedOrganization = organization;
    }
  }
};
</script>

<style scoped>
@import "@/assets/css/publicStyle.css";

/* Add your CSS styling here */
.organization-management {
  display: flex;
  height: 100%;
  /* 设置高度为100%或其他适当的高度值 */

}

/* 添加样式规则来调整分隔线样式 */
.separator {
  border: none;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  /* 更透明的横线 */
  margin: 8px 0;
  /* 调整分隔线上下的间距 */
  width: 100%;
  /* 横线占满整个宽度 */

}

/*用户管理/组织管理的样式*/
.custom-text {
  font-size: 14px;
  text-align: left;
  margin-bottom: 15px;
  margin-top: 15px;

}

/*最顶部的用户管理字体颜色*/
.user-manage {
  color: rgba(0, 0, 0, .45);
}

.directory {
  width: 30%;
  padding: 10px;
  border-right: 1px solid #e0e0e0;
  flex: 0 0 250px;
  /* 这里的 250px 是假定的宽度值，您可以根据实际需要调整 */
}

.content {
  width: 70%;
  padding: 20px;
  flex-grow: 1;
}
</style>
