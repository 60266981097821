<template>

  <div>
    <div class="custom-text">
      <span class="user-manage">{{ $t('userManage.userManagement') }}</span>
      &nbsp;/&nbsp; {{ $t('userManage.roleManagement') }}
    </div>
    <hr class="separator" style="margin-bottom: 20px;"/>

    <!-- 添加全选/反选勾选框 -->
    <div style="text-align: right; margin-bottom: 10px;">
      <el-checkbox v-model="isAllSelected" @change="toggleSelectAll"><b>{{ $t('userManage.selectAllDeselectAll') }}</b></el-checkbox>
    </div>

  </div>
  <div style="height: 100%;">
    <el-row style="height: 100%;" :gutter="20">
      <el-col :span="6">
        <h3 style="text-align: left; margin-left: 20px;">{{ $t('userManage.roleList') }}</h3>
        <el-tree v-loading="roleLoading" style="min-width: 100px;max-height: 700px; overflow-y: auto;" :data="roleTree" node-key="id"
                 @node-click="nodeClick">
          <template #default="{ node, data }">
                        <span class="custom-tree-node">
                            <span>{{ node.label }}</span>
                            <span>
                                <el-icon @click.stop="edit(data)">
                                    <Edit/>
                                </el-icon>
                                <el-popconfirm :title="$t('userManage.sureDelete')" width="250" @confirm.stop="remove(node, data)">
                                    <template #reference>
                                        <el-icon style="margin-left: 8px" @click.stop="">
                                            <Delete/>
                                        </el-icon>
                                    </template>
                                </el-popconfirm>

                            </span>
                        </span>
          </template>
        </el-tree>
        <el-button class="custom-reset-button" @click="addRoleVisible = true" style="margin-top: 40px;">{{
            $t('userManage.addRole')
          }}
        </el-button>
      </el-col>
      <el-col :span="18">
        <div v-loading="menuLoading" v-if="currentRole.name !== undefined" style="height: 100%;">
          <h3>{{ currentRole.name }}</h3>
          <el-collapse v-model="activeNames">
            <el-collapse-item style="text-align: left;" v-for="item in menuTree" :key="item.id"
                              :title="item.title" :name="item.name">

              <div style="text-align: right;">
                <el-switch v-model="item.switch" @change="clearCheckboxes(item)"/>
              </div>

              <div v-if="item.menus?.length > 0 || item.permissions?.length > 0">
                <div v-if="item.menus?.length > 0" class="child">
                  <div>{{ $t('userManage.submenu') }}</div>
                  <el-checkbox v-model="menu.isChecked" :disabled="!item.switch"
                               v-for="menu in item.menus" :key="menu.id" :label="menu.title"/>
                </div>
                <div class="child" v-if="item.permissions?.length > 0" style="margin-top: 20px;">
                  <div>{{ $t('userManage.actionPermission') }}</div>
                  <el-checkbox v-model="permission.isChecked" :disabled="!item.switch"
                               v-for="permission in item.permissions" :key="permission.id"
                               :label="permission.title"/>
                </div>

              </div>
              <div v-else
                   style="padding: 24px;background-color: rgb(250,250,250);display: flex;flex-direction: row;align-items: center;">
                <el-icon size="14">
                  <Warning/>
                </el-icon>
                <div style="margin-left: 8px; line-height: 14px;">{{
                    $t('userManage.noSubmenuOrActionPermission')
                  }}
                </div>
              </div>

            </el-collapse-item>
          </el-collapse>
          <div style="margin-top: 20px;text-align: right;">
            <el-button type="primary" :loading="roleSaveLoading" @click="saveRolePermissions">{{
                $t('userManage.save')
              }}
            </el-button>
          </div>

        </div>
        <div v-else style="margin-top: 120px;color: #e2e2e2;">
          <el-icon size="26px">
            <Box/>
          </el-icon>
          <div style="font-size: 20px;">{{ $t('userManage.pleaseSelectRole') }}</div>
        </div>

      </el-col>
    </el-row>
  </div>

  <el-dialog v-model="editRoleVisible" :title="$t('userManage.modify')" width="500">
    <el-input v-model="editRole.name"></el-input>
    <template #footer>
      <div class="dialog-footer">
        <el-button type="primary" @click="saveRole">
          {{ $t('userManage.save') }}
        </el-button>
      </div>
    </template>
  </el-dialog>

  <el-dialog v-model="addRoleVisible" :title="$t('userManage.createRole')" width="500" style="text-align: left;">
    <el-form ref="ruleFormRef" :model="roleForm" label-position="top" :rules="rules">
      <el-form-item :label="$t('userManage.roleName')" prop="name">
        <el-input v-model="roleForm.name" autocomplete="off"/>
      </el-form-item>
      <el-form-item :label="$t('userManage.copyRole')" prop="rolePermission">
        <el-select v-model="roleForm.rolePermission" :placeholder="$t('userManage.selectRole')"
                   style="width: 100%"
                   :clearable="true">
          <el-option v-for="role in roleTree" :key="role.id" :label="role.name" :value="role.id"/>
        </el-select>
      </el-form-item>
    </el-form>
    <template #footer>
      <div class="dialog-footer">
        <el-button class="custom-reset-button" @click="addRole(ruleFormRef)">
          {{ $t('userManage.confirmAction') }}
        </el-button>
      </div>
    </template>
  </el-dialog>

</template>
<script setup>

import {onMounted, ref} from 'vue';
import api from '../api'
import {ElMessage} from 'element-plus'
import {Box, Delete, Warning} from "@element-plus/icons-vue";
import i18n from "@/i18n";

const t = i18n.global.t; // 将 i18n.global.t 方法赋值给变量 t

const menuTree = ref([])
const roleTree = ref([])
const activeNames = ref([])
const currentRole = ref({})
const editRole = ref({})
const editRoleVisible = ref(false)
const roleSaveLoading = ref(false)
const addRoleVisible = ref(false)
const menuLoading = ref(false)
const roleLoading = ref(false)

const roleForm = ref({
  name: '',
  rolePermission: null
})

const ruleFormRef = ref()
const rules = ref({
  name: [{
    required: true,
    message: (value) => value || t('userManage.enterName'),
    trigger: 'blur'
  }]
})

async function initData() {

  getRoleList()
  menuList()
}

onMounted(() => {
  initData()
})


function nodeClick(node) {
  menuLoading.value = true
  currentRole.value = node
  menuList().then(() => {
    api.role(currentRole.value.id).then(res => {
      menuTree.value = menuTree.value.map(v => {
        let selctRoleMenu = res.data.menuPermissions.find(p => {
          return p.name === v.name
        })
        v.switch = selctRoleMenu === undefined ? false : true

        if (v.switch && selctRoleMenu?.children?.length > 0 && v?.menus?.length > 0) {
          v.menus = v.menus.map(vm => {
            vm.isChecked = selctRoleMenu.children.find(rc => {
              return rc.name === vm.name
            }) === undefined ? false : true
            return vm
          })
        }
        if (v.switch && selctRoleMenu?.permissions.length > 0 && v?.permissions.length > 0) {
          v.permissions = v.permissions.map(vp => {
            vp.isChecked = selctRoleMenu.permissions.find(rp => {
              return rp.name === vp.name
            }) === undefined ? false : true
            return vp
          })
        }
        return v
      })
      activeNames.value = menuTree.value.filter(v => {
        return v.switch
      }).map(v => {
        return v.name
      })

    })
  }).catch(err => {
    ElMessage(err?.message || t('userManage.getDataError'))
  }).finally(() => {
    menuLoading.value = false
  })

}

async function menuList() {
  await api.menuList().then(res => {
    menuTree.value = (res?.list || []).map(item => {
      if (item.permissions !== undefined) {
        item.permissions = item.permissions.map(v => {
          return {isChecked: false, ...v}
        })
      }
      if (item.menus !== undefined) {
        item.menus = item.menus.map(v => {
          return {isChecked: false, ...v}
        })
      }
      return {switch: false, ...item}
    })
  })
}

function getRoleList() {
  roleLoading.value = true
  api.roleSimple().then(res => {
    roleTree.value = res.data.map(item => {
      return {label: item.name, ...item}
    })
    roleLoading.value = false
  })
}

function edit(e) {
  editRole.value = e
  editRoleVisible.value = true
}

function remove(e) {
  api.roleDelete(e.data.id).then(() => {
    getRoleList()
    if (currentRole.value.id === e.data.id) {
      currentRole.value = {}
    }
    ElMessage({
      type: 'success',
      message: t('userManage.deleteSuccess')
    })
  }).catch(err => {
    ElMessage(err?.message || t('userManage.deleteErrorMessage'))
  })
}

function saveRole() {
  roleSaveLoading.value = true
  api.updateRole({
    id: editRole.value.id,
    name: editRole.value.name
  }).then(() => {
    getRoleList()
    ElMessage({
      message: t('userManage.modifySuccess'),
      type: 'success',
    })
  }).catch(err => {
    ElMessage({
      message: err?.message || t('userManage.updateErrorMessage'),
      type: 'error'
    })
  }).finally(() => {
    editRoleVisible.value = false
    roleSaveLoading.value = false
  })
}

async function addRole(formEl) {
  if (!formEl) return
  await formEl.validate((valid) => {
    if (valid) {
      api.addRole(roleForm.value).then(() => {
        getRoleList()
        addRoleVisible.value = false
        roleForm.value = {
          name: '',
          rolePermissionL: null
        }
        ElMessage({
          type: 'success',
          message: t('userManage.addSuccessMessage')
        })
      }).catch(err => {
        ElMessage(err?.message || t('userManage.addErrorMessage'))
      })
    } else {
      ElMessage({
        type: 'error',
        message: t('userManage.checkFieldsMessage')
      })
    }
  })
}

function saveRolePermissions() {
  let menuIds = []
  let permissionIds = [];
  let hasMainMenuSelected = false;

  menuLoading.value = true

  menuTree.value.map(m => {
    if (m.switch) {
      hasMainMenuSelected = true; // 如果有一个主菜单项被选中，则设置为true
      menuIds.push(m.id)
      if (m?.menus.length > 0) {
        m.menus.map(mm => {
          if (mm.isChecked) menuIds.push(mm.id)
        })
      }
      if (m?.permissions.length > 0) {
        m.permissions.forEach(mp => {
          if (mp.isChecked) permissionIds.push(mp.id)
        });
      }
    }
  });

  if (!hasMainMenuSelected) {
    ElMessage({
      type: 'error',
      message: t('userManage.selectAtLeastOneMainMenu') // 提示用户选择至少一个主菜单项
    });
    menuLoading.value = false;
    return;
  }

  api.roleMenuPermissions({
    role_id: currentRole.value.id,
    menu_ids: menuIds,
    permission_ids: permissionIds
  }).then(() => {
    ElMessage({
      type: 'success',
      message: t('userManage.saveSuccess')
    })
  }).catch(err => {
    ElMessage(err?.message || t('userManage.updateErrorMessage'))
  }).finally(() => {
    menuLoading.value = false
  })
}

const isAllSelected = ref(false);

function toggleSelectAll() {
  menuTree.value.forEach((item) => {
    if (item.switch) {
      item.menus.forEach((menu) => {
        menu.isChecked = isAllSelected.value;
      });
      item.permissions.forEach((permission) => {
        permission.isChecked = isAllSelected.value;
      });
    }
  });
}

function clearCheckboxes(item) {
  if (!item.switch) {
    if (item.menus) {
      item.menus.forEach(menu => {
        menu.isChecked = false;
      });
    }
    if (item.permissions) {
      item.permissions.forEach(permission => {
        permission.isChecked = false;
      });
    }
  }
}


</script>
<style>
.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}

.child {
  border: 1px solid rgba(0, 0, 0, 0.06);
  background-color: rgb(250, 250, 250);
  padding: 16px 24px
}
</style>