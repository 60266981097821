<template>
  <div class="qrcode-table" v-loading="loading" element-loading-text="Loading...">
    <div class="batch-operations" v-if="selectedRows.length > 0">
      <span class="hasChosen">{{ $t('userManage.selected') }}&nbsp;{{
          selectedRows.length
        }}&nbsp;{{ $t('userManage.items') }}</span>
      <el-button @click="batchPrintQRCodes(selectedRows)" link><span
          class="link-text-style">{{ $t('userManage.batchPrint') }}</span>
      </el-button>
    </div>
    <el-table :header-cell-style="{ background: 'rgb(250, 250, 250)' }" :data="tableData" style="width: 100%"
              :row-style="{ height: '83px' }"
              row-key="id"
              :border="false"
              @select="handleSelect"
              @select-all="handleSelectAll"
              :row-class-name="tableRowClassName"
              class="table-height"
    >
      <el-table-column type="selection"></el-table-column>
      <el-table-column :label="$t('userManage.secondaryOrganization')" prop="org"></el-table-column>
      <el-table-column :label="$t('userManage.QRCodeStatus')" prop="status"></el-table-column>
      <el-table-column :label="$t('userManage.creationTime')" prop="createdTime"
                       :formatter="formatDate"></el-table-column>

      <el-table-column :label="$t('userManage.QRCodeExpirationTime')" prop="invalidTime"></el-table-column>
      <el-table-column :label="$t('userManage.QRCode')" prop="qrCode">
        <template v-slot="scope">
          <img :src="scope.row.qrCode" @click="showImg(scope.row.qrCode)" alt="QR Code" width="50" height="50">
        </template>
      </el-table-column>
      <el-table-column :label="$t('userManage.operation')">
        <template v-slot="scope">
          <el-popconfirm :title="$t('userManage.confirmPrint')"
                         confirm-button-type="success" width="250"
                         placement="top" @confirm="printQRCode(scope.row)">
            <template #reference>
              <el-button link><span class="link-text-style">{{ $t('userManage.print') }}</span></el-button>
            </template>

          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>

    <!-- 展示图片弹框   -->
    <el-dialog
        v-model="showImgVisible"
        width="420"
        style="text-align: left"
        :title="$t('userManage.QRCode')"
        class="custom-dialog"
    >
      <hr class="top-separator"/>
      <div class="content-center">
        <img :src="currentImg" alt="QR Code" width="200" height="200">
      </div>
    </el-dialog>
  </div>


</template>

<script>
import resizeMixin from '@/mixins/resizeMixin';
import dialogMixin from "@/mixins/dialogMixin";

export default {
  mixins: [resizeMixin, dialogMixin],

  data() {
    return {
      showImgVisible: false,
      currentImg: '',
      selectedRows: [],//表示表格多选框选中的行
    }
  },
  props: {
    tableData: Array, // 传递表格数据
    loading: Boolean  // 接收 loading 状态
  },
  methods: {
    tableRowClassName({row}) {
      if (this.selectedRows.some(selectedRow => selectedRow.id === row.id)) {
        return 'row-selected';
      }
      return '';
    },


    showImg(imgUrl) {
      this.showImgVisible = true;
      this.currentImg = imgUrl;
    },
    handleSelect(selection) {
      this.selectedRows = selection;
    },
    handleSelectAll(selection) {
      this.selectedRows = selection;
    },

  },
};
</script>

<style scoped>

.print-container .print-page {
  page-break-after: always;
}

@media print {
  body * {
    visibility: hidden; /* 隐藏页面上的其他内容 */
  }

  .print-container, .print-container * {
    visibility: visible; /* 仅显示打印内容 */
  }

  .print-container {
    position: absolute;
    left: 0;
    top: 0;
  }
}

/* 样式 */
.qrcode-table {
  margin-top: 20px;
  margin-left: 20px;
}

/*表格上方的div方框样式*/
.batch-operations {
  border: 1px solid rgb(0, 154, 68);
  background-color: rgb(238, 250, 235);
  text-align: left;
  height: 40px; /* 设置合适的高度值 */

  display: flex;
  gap: 20px;
  align-items: center;
  margin-bottom: 15px;
}

.hasChosen {
  margin-left: 10px;
  font-size: 14px;
  margin-right: 10px;
}
</style>
