<template>
  <div class="button-group">
    <el-button class="button-background-color" @click="createQRCode">
      <el-icon>
        <Plus/>
      </el-icon>&nbsp;
      {{ $t('userManage.addQRCode') }}
    </el-button>
    <el-button type="info" @click="batchCreateQRCode" :disabled="isBatchButtonDisabled" class="custom-button">
      <el-icon>
        <Plus/>
      </el-icon>&nbsp;
      {{ $t('userManage.newQRCodeThisTime') }}
    </el-button>
  </div>

  <!-- 新增二维码的弹框 -->
  <el-dialog :title="$t('userManage.addQRCode')" style="text-align: left" v-model="addQrcodeDialogVisible" width="600"
             :before-close="handleAddQrcodeDialogClose" @open="disableScroll" @close="enableScroll">
    <hr class="top-separator"/>

    <el-form ref="addQrcodeFormRef" :model="addQrcodeForm" :rules="addQrcodeFormRules" label-position="top"
             class="full-width-form">
      <el-form-item class="form-item-padding" prop="secondOrganization">
        <label> <span style="color: rgb(245,108,108)">*</span> {{ $t('userManage.secondaryOrganization') }}</label>
        <el-select v-model="addQrcodeForm.secondOrganization" :placeholder="$t('userManage.pleaseSelectOrganization')"
                   clearable filterable
                   style="width: 100%;">
          <el-option v-for="org in secondOrganization" :key="org.value" :label="org.label"
                     :value="org.value"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item class="form-item-padding" prop="roleName">
        <label> <span style="color: rgb(245,108,108)">*</span> {{ $t('userManage.role') }}</label>
        <el-select v-model="addQrcodeForm.roleName" :placeholder="$t('userManage.pleaseSelectRole')" clearable
                   filterable style="width: 100%;">
          <el-option v-for="org in roleNameOptions" :key="org.value" :label="org.label" :value="org.value"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item :label="$t('userManage.quantity')" prop="number">
        <el-input-number v-model="addQrcodeForm.number" :placeholder="$t('userManage.enterQuantity')" :min="1" :step="1"
                         controls-position="right"
                         clearable class="custom-input-number"></el-input-number>
      </el-form-item>

    </el-form>
    <hr class="dialog-separator"/>

    <div class="dialog-footer">
      <el-button @click="cancelAddQrcode" class="custom-reset-button"> {{ $t('userManage.cancel') }}</el-button>
      <el-button class="button-background-color" @click="confirmAddQrcode"> {{ $t('userManage.confirm') }}</el-button>
    </div>
  </el-dialog>

  <el-dialog :title="$t('userManage.newQRCodeThisTime')" v-model="showNewQRCodesDialog" width="1000"
             style="text-align: left"
             :before-close="handleClose" @open="disableScroll" @close="handleDialogClose">
    <div class="qrcode-table">

      <div class="batch-operations" v-if="selectedRows.length > 0">
        <span class="hasChosen">{{ $t('userManage.selected') }}&nbsp;{{
            selectedRows.length
          }}&nbsp; {{ $t('userManage.items') }}</span>&nbsp;&nbsp;
        <el-button @click="batchPrintQRCodes(selectedRows)" link><span class="link-text-style">{{
            $t('userManage.batchPrint')
          }}</span></el-button>

      </div>

      <el-table :header-cell-style="{ background: 'rgb(250, 250, 250)' }" :data="newQRCodesData" style="width: 100%"
                :row-style="{ height: '83px' }" row-key="id" :border="false" @select="handleSelect"
                @select-all="handleSelectAll" :row-class-name="tableRowClassName">
        <el-table-column type="selection"></el-table-column>
        <el-table-column :label="$t('userManage.secondaryOrganization')" prop="org" min-width="156"
                         max-width="413"></el-table-column>
        <el-table-column :label="$t('userManage.QRCodeStatus')" prop="status"></el-table-column>
        <el-table-column :label="$t('userManage.creationTime')" prop="createdTime"
        ></el-table-column>
        <el-table-column :label="$t('userManage.QRCodeExpirationTime')" prop="invalidTime"
        ></el-table-column>
        <el-table-column :label="$t('userManage.QRCode')" prop="qrCode">
          <template v-slot="scope">
            <img :src="scope.row.qrCode" @click="showImg(scope.row.qrCode)" alt="QR Code" width="50" height="50">
          </template>
        </el-table-column>

        <el-table-column :label="$t('userManage.action')">
          <template v-slot="scope">
            <el-popconfirm :title="$t('userManage.confirmPrint')" confirm-button-type="success" placement="top"
                           @confirm="printQRCode(scope.row)" width="250">
              <template #reference>
                <el-button link><span class="link-text-style">{{ $t('userManage.print') }}</span></el-button>
              </template>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>

      <!-- 展示图片弹框   -->
      <el-dialog v-model="showImgVisible" width="420" style="text-align: left"
                 :title="$t('userManage.QRCode')"
                 class="custom-dialog">
        <hr class="top-separator"/>
        <div class="content-center">
          <img :src="currentImg" alt="QR Code" width="200" height="200">
        </div>
      </el-dialog>

      <div class="right-container">
        <PaginationBar :currentPage="currentPage" :pageSize="pageSize" :totalRecords="total"
                       @size-change="handleSizeChange" @current-change="handleCurrentChange"/>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import {Plus} from "@element-plus/icons-vue";
import searchCondition from "@/views/AfterSales/components/SearchCondition.vue";
import PaginationBar from "@/components/PaginationBar.vue";
import {addSelfRegister, getRoleData, getSelfRegister, getTwoLevelOrganizations} from "@/api/api";
import {ElMessage} from "element-plus";
import dialogMixin from "@/mixins/dialogMixin";

export default {
  computed: {
    searchCondition() {
      return searchCondition
    }
  },
  components: {PaginationBar, Plus},
  data() {
    return {
      isBatchButtonDisabled: true, // 控制第二个按钮是否禁用
      showImgVisible: false,

      currentImg: '',
      addQrcodeDialogVisible: false,
      addQrcodeForm: {
        secondOrganization: '',
        roleName: '',
        number: '',
        qrcode: 0,
      },
      addQrcodeFormRules: {
        secondOrganization: [
          {
            required: true, message: this.$t('userManage.pleaseSelectOrganization'), trigger: 'blur'
          },
        ],
        roleName: [
          {
            required: true, message: this.$t('userManage.pleaseSelectRole'), trigger: 'blur'
          }
        ],
        number: [
          {
            required: true, message: this.$t('userManage.enterQuantity'), trigger: 'blur'
          },
        ],
      },

      secondOrganization: [],

      roleNameOptions: [],
      showNewQRCodesDialog: false,
      selectedRows: [],

      newQRCodesData: [],
      currentPage: 1,
      pageSize: 10,
      total: 0,
    }
  },
  created() {
    this.fetchSecondOrganizationOptions();
    this.fetchRoleNameOptions();
  },
  props: {
    loadData: {
      type: Function,
      required: true,
    },
  },
  mixins: [dialogMixin],

  methods: {
    // 新增方法：处理弹框关闭时的逻辑
    handleAddQrcodeDialogClose(done) {
      this.resetAddQrcodeForm();
      done();
    },
    // 新增方法：重置表单数据
    resetAddQrcodeForm() {
      this.addQrcodeForm = {
        secondOrganization: '',
        roleName: '',
        number: '',
        qrcode: 0,
      };
    },
    tableRowClassName({row}) {
      if (this.selectedRows.some(selectedRow => selectedRow.id === row.id)) {
        return 'row-selected';
      }
      return '';
    },
    showImg(imgUrl) {
      this.showImgVisible = true;
      this.currentImg = imgUrl;
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.currentPage = 1; // 切换每页显示数量时重置当前页码
    },
    handleCurrentChange(page) {
      this.currentPage = page;
    },
    createQRCode() {
      // 新增二维码逻辑
      this.addQrcodeDialogVisible = true;
    },
    batchCreateQRCode() {
      // 本次新增二维码逻辑
      this.showNewQRCodesDialog = true; // 显示第二个弹窗
    },
    cancelAddQrcode() {
      this.addQrcodeDialogVisible = false;
      this.resetAddQrcodeForm();  // 在这里重置表单数据
    },

    async confirmAddQrcode() {
      await this.$refs.addQrcodeFormRef.validate(async (valid) => {
        if (valid) {
          try {
            // 在这里可以处理表单数据提交的逻辑
            const formData = {
              organization_id: this.addQrcodeForm.secondOrganization,
              role_id: this.addQrcodeForm.roleName,
              number: this.addQrcodeForm.number,
              url: this.addQrcodeForm.qrcode,
            };

            // 发送数据到后端API，调用添加数据的接口
            const response = await addSelfRegister(formData);

            // 检查返回的code是否为0
            if (response.code !== 0) {
              ElMessage.error(response.message);
              return;
            }

            // 添加数据成功后，关闭弹窗
            this.showNewQRCodesDialog = true;
            this.addQrcodeDialogVisible = false;
            // 清空表单数据
            this.addQrcodeForm = {
              secondOrganization: '',
              roleName: '',
              number: '',
              qrcode: 0,
            };
            await this.loadData();
            // 在这里可以进行其他的提示或跳转等操作
            // 示例中只是简单地打印一条消息
            ElMessage.success(this.$t('userManage.operationSuccess'));

            try {
              // 直接使用组件的状态（currentPage和pageSize）作为分页参数
              const page = this.currentPage;
              const pageSize = this.pageSize;

              // 调整查询对象，确保键名为 'id'
              let searchCondition = {};
              if (response.data) {
                searchCondition["id-in"] = response.data;
              }

              // 从getSelfRegister获取数据，并传递分页和查询参数
              const data = await getSelfRegister(page, pageSize, searchCondition);

              if (!Array.isArray(data.data)) {
                throw new Error('Expected an array of data');
              }

              // 转换数据格式，根据实际返回的数据结构调整字段名
              this.newQRCodesData = data.data.map(item => ({
                org: item.organization,
                status: item.status,
                createdTime: item.created_at,
                invalidTime: item.invalid_at,
                qrCode: item.qr_code_url,
              }));

              this.total = data.meta.total; // 更新总记录数
              this.currentPage = data.meta.current_page; // 更新当前页（如果后端返回当前页信息）
              this.pageSize = parseInt(data.meta.per_page, 10); // 更新每页记录数

            } catch (error) {
              console.error('Error loading data:', error);
              // 可以在这里处理错误，例如显示错误消息
            }

          } catch (error) {
            ElMessage.error(this.$t('userManage.operationFail'));
            // 处理错误，可以在界面上显示错误信息等
            console.error('Error adding Qrcode:', error);
          }
        } else {
          ElMessage.error(this.$t('userManage.fill_required_fields'));
          return false;
        }
      });
    },

    async fetchSecondOrganizationOptions() {
      try {
        // 调用后端接口获取组织数据
        let searchCondition = {
          "level-eq": 2
        };

        const response = await getTwoLevelOrganizations(searchCondition);
        // 将后端返回的组织数据转换成el-select所需的格式
        this.secondOrganization = response.data.map(org => ({
          label: org.name, // 组织名称，根据后端返回的数据结构调整
          value: org.id   // 组织值，根据后端返回的数据结构调整
        }));
      } catch (error) {
        console.error('Error fetching organizations:', error);
      }
    },
    //获取角色数据
    async fetchRoleNameOptions() {
      try {
        const response = await getRoleData();

        // 将后端返回的组织数据转换成el-select所需的格式
        this.roleNameOptions = response.data.map(org => ({
          label: org.name, // 组织名称，根据后端返回的数据结构调整
          value: org.id   // 组织值，根据后端返回的数据结构调整
        }));
      } catch (error) {
        console.error('Error fetching role:', error);
      }
    },
    handleClose(done) {
      // 弹窗关闭前的逻辑
      done();
    },
    closeDialog() {
      this.dialogVisible = false;
    },
    batchPrint() {
      // 批量打印逻辑，类似于您现有的 batchPrintQRCodes 方法
    },
    handleSelect(selection) {
      this.selectedRows = selection;
    },
    handleSelectAll(selection) {
      this.selectedRows = selection;
    },

  },
};
</script>

<style scoped>
/* 去除弹框内容的内边距 */
:deep(.el-dialog__body) {
  padding-bottom: 0;
  /* 或者您想要的较小的值 */
}

/* 如果您还想调整弹框的顶部、左侧或右侧内边距 */
:deep( .el-dialog__body) {
  padding: 20px 15px 5px 20px;
  /* 顶部、左右侧保持 20px 内边距，底部为 0 */
}

/* 如果 .el-dialog 有额外的样式需要覆盖 */
:deep( .el-dialog) {
  padding: 0;
  /* 如果您可以控制弹窗的内边距，将其设置为0 */
}

.custom-input-number {
  width: 150px;
  /* 调整为所需的宽度 */
}

.full-width-form :deep( .custom-input-number .el-input-number__decrease),
.full-width-form :deep(.custom-input-number .el-input-number__increase) {
  display: none;
}

.full-width-form :deep( .custom-input-number:hover .el-input-number__decrease),
.full-width-form :deep(.custom-input-number:hover .el-input-number__increase) {
  display: block;
}

.full-width-form .el-form-item {
  margin-right: 0;
  margin-left: 0;
}

.form-item-padding {
  padding-right: 4px;
  padding-left: 4px;
}

:deep( .el-form-item__label) {
  margin-bottom: 0;
  /* 减少label下方的外边距 */
  padding-bottom: 0;
  /* 减少label的内边距 */
}

.custom-button {
  background-color: rgb(245, 245, 245);
  /* 设置背景色为红色 */
  border-color: rgb(217, 217, 217);
  /* 设置边框颜色为红色，如果需要 */
  color: rgb(184, 184, 184);
  /* 设置边框颜色为红色，如果需要 */

}

.custom-button:hover {
  background-color: rgb(245, 245, 245);
  /* 鼠标悬停时保持相同的背景色 */
  border-color: rgb(217, 217, 217);
  /* 鼠标悬停时保持相同的边框颜色 */
  color: rgb(184, 184, 184);
  /* 鼠标悬停时保持相同的文字颜色 */
}

/* 添加按钮组样式 */
.button-group {
  display: flex;
  justify-content: flex-start;
  margin-left: 20px;
  margin-top: 20px;
}

.dialog-footer {
  display: flex;
  justify-content: flex-end;
}
</style>