<template>
  <hr class="separator " style="margin-top: 1px" />

  <div>
    <div class="custom-text">
      <span class="user-manage"> {{ $t('userManage.userManagement') }}</span>
      &nbsp;/&nbsp; {{ $t('userManage.selfRegAccountManagement') }}
    </div>
    <hr class="separator" />
  </div>

  <div class="self-registration">
    <div class="container">
      <div class="left-container">
        <SearchBar @search="search" />
        <ButtonGroup :loadData="loadData" />
        <QRCodeTable :table-data="tableData" @print="printQRCode" @check-all="checkAll" :loading="loading"/>
      </div>
      <div class="right-container">
        <PaginationBar :currentPage="currentPage" :pageSize="pageSize" :totalRecords="totalRecords"
          @page-size-change="handleSizeChange" @page-change="handleCurrentChange" />
      </div>
    </div>
  </div>
</template>

<script>
import SearchBar from './components/SearchBar.vue';
import QRCodeTable from './components/QRCodeTable.vue';
import PaginationBar from '@/components/PaginationBar.vue';
import ButtonGroup from './components/ButtonGroup.vue';
import {getSelfRegister} from "@/api/api";

export default {
  components: {
    SearchBar,
    QRCodeTable,
    PaginationBar,
    ButtonGroup,
  },
  data() {
    return {
      tableData: [], // 初始化为空数组
      currentPage: 1,
      pageSize: 10,
      totalRecords: 0,
      loading: false, // 加载状态
    };
  },
  created() {
    this.loadData();
  },
  watch: {
    '$i18n.locale'() {
      this.search(this.searchParams);
    },
  },
  methods: {
    // 其他方法不变
    async search(searchParams) {
      const page = this.currentPage;
      const pageSize = this.pageSize;
      this.isSearching = true;
      this.searchParams = searchParams;
      this.loading = true; // 开始加载
      try {
        // 使用 await 等待异步函数的结果
        const response = await getSelfRegister(page, pageSize, searchParams);
        // 进行数据转换
        this.tableData = response.data.map(item => ({
          id: item.id,
          org: item.organization,
          status: item.status === 1 ? this.$t('userManage.valid') : this.$t('userManage.invalid'),
          createdTime: item.created_at,
          invalidTime: item.invalid_at,
          qrCode: item.qr_code_url,
          // 其他字段映射
        }));
      } catch (error) {
        // 使用 try/catch 捕获异步操作中的错误
        console.error('Error:', error);
      } finally {
        this.loading = false; // 加载结束
      }
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.currentPage = 1; // 切换每页显示数量时重置当前页码
      this.loadData()
    },
    handleCurrentChange(page) {
      this.currentPage = page;
      this.loadData()
    },

    async loadData() {
      this.isSearching = true; // 假设有一个isSearching状态用于显示加载中的UI
      this.loading = true; // 开始加载
      try {
        // 直接使用组件的状态（currentPage和pageSize）作为分页参数
        const page = this.currentPage;
        const pageSize = this.pageSize;

        const data = await getSelfRegister(page, pageSize);

        if (!Array.isArray(data.data)) {
          throw new Error('Expected an array of data');
        }

        // 转换数据格式，根据实际返回的数据结构调整字段名
        this.tableData = data.data.map(item => ({
          id: item.id,
          org: item.organization,
          status: item.status === 1 ? this.$t('userManage.valid') : this.$t('userManage.invalid'),
          createdTime: item.created_at,
          invalidTime: item.invalid_at,
          qrCode: item.qr_code_url,
        })); // 更新表格数据
        this.totalRecords = data.meta.total; // 更新总记录数
        this.currentPage = data.meta.current_page; // 更新当前页（如果后端返回当前页信息）
        this.pageSize = parseInt(data.meta.per_page, 10); // 更新每页记录数

      } catch (error) {
        console.error('Error loading data:', error);
        // 可以在这里处理错误，例如显示错误消息
      } finally {
        this.isSearching = false; // 加载完成，无论成功或失败
        this.loading = false; // 加载结束
      }
    },
  },
};
</script>
<style scoped>
.container {
  display: flex;
  flex-direction: column;
}

.right-container {
  display: flex;
  justify-content: flex-end;
}

/*顶部的 用户管理/自注册账号管理 文字的样式*/
.custom-text {
  font-size: 14px;
  text-align: left;
  margin-bottom: 15px;
  margin-top: 15px;
}

.user-manage {
  color: rgba(0, 0, 0, .45);
}

.separator {
  border: none;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  /* 更透明的横线 */
  margin: 8px 0;
  /* 调整分隔线上下的间距 */
  width: 100%;
  /* 横线占满整个宽度 */
}
</style>