<template>
  <div class="search-bar">
    <el-form inline class="compact-form" label-position="top">
      <el-form-item :label="$t('userManage.secondaryOrganization')">
        <el-select v-model="selectedOrg" :placeholder="$t('userManage.pleaseSelectOrganization')" clearable filterable>
          <el-option
              v-for="org in orgOptions"
              :key="org.value"
              :label="org.label"
              :value="org.value"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item :label="$t('userManage.QRCodeStatus')">
        <el-select v-model="selectedQRCodeStatus" :placeholder="$t('userManage.selectQRCodeStatus')" clearable filterable>
          <el-option :label="$t('userManage.valid')" value="1"></el-option>
          <el-option :label="$t('userManage.invalid')" value="0"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="&nbsp;">
        <el-button class="button-background-color " @click="search">{{ $t('userManage.search') }}</el-button>
        <el-button @click="resetSearchForm" class="custom-reset-button ">{{ $t('userManage.reset') }}</el-button>
      </el-form-item>

    </el-form>
  </div>

</template>

<script>

import {getTwoLevelOrganizations} from "@/api/api";

export default {
  data() {
    return {
      selectedOrg: '',
      selectedQRCodeStatus: '',
      orgOptions: []   // 用于存储从后端获取的组织数据
    };
  },
  async created() {
    try {
      // 调用后端接口获取组织数据
      let searchCondition = {};

      searchCondition["level-eq"] = 2;

      const organizations = await getTwoLevelOrganizations(searchCondition);

      // 将后端返回的组织数据转换成el-select所需的格式
      this.orgOptions = organizations.data.map(org => ({
        label: org.name, // 组织名称，根据后端返回的数据结构调整
        value: org.id   // 组织值，根据后端返回的数据结构调整
      }));
    } catch (error) {
      console.error('Error fetching organizations:', error);
    }
  },
  methods: {
    search() {

      let searchCondition = {};

      if (this.selectedOrg) {
        searchCondition["organization_id-eq"] = this.selectedOrg;
      }

      if (this.selectedQRCodeStatus) {
        searchCondition["status-eq"] = this.selectedQRCodeStatus;
      }

      this.$emit('search', searchCondition);
    },
    resetSearchForm() {
      this.selectedOrg = '';
      this.selectedQRCodeStatus = '';
      this.search();
    },
  },
};
</script>

<style scoped>

.el-select {
  min-width: 250px; /* 或者你想要的任何适当的宽度 */
}

.compact-form .el-form-item {
  margin-bottom: 3px; /* 减少下方外边距 */
}

/* 样式 */
.search-bar {
  display: flex;
  margin-top: 20px;
  margin-left: 20px;
  align-items: center;

  border: 1px solid rgb(236, 239, 239);
  background-color: rgb(255, 255, 255);
  border-radius: 2px;
  margin-right: 20px;
  padding: 15px 0 20px 25px;
}

</style>
  